.project-card-container {
  margin: 10px 0;
  cursor: pointer;
  height: 74px;
  width: fit-content;
  display: flex;
  align-items: center;
  .user {
    display: flex;
    align-items: center;
    height: 45px;

    &:hover {
      .assignees-list-wrapper {
        display: flex;
      }
    }
  }
  p,
  .zones {
    width: 200px;
    font-weight: bold;
    padding: 10px 25px 10px 10px;
    border-right: 0.2px solid #E6EEF4;
    font-size: 14px;
  }
  p:nth-of-type(1),
  p:nth-of-type(2) {
    width: 250px;
  }
  .user > p {
    width: 200px;
    padding-right: 20px;
  }

  .status-container {
    background: #6041ed;
    color: white;
    font-weight: bold;
    width: 200px;
    display: flex;
    align-items: center;
    height: 45px;
    font-weight: bold;
    margin: 0px 25px 0px 10px;
    font-size: 14px;
    .status-paragraph {
      border: none;
      width: 100%;
    }
  }
  .zones {
    display: flex;
    align-items: center;
    border: none;

    span {
      margin: 0 5px;
    }
  }

  .assignees-wrapper {
    display: flex;
    align-items: center;
    width: 200px;
    border-right: 0.2px solid #E6EEF4;
    height: 45px;
    position: relative;

    p {
      width: 150px;
      border-right: none;
    }

    span {
      white-space: nowrap;
      z-index: 3;
    }
  }

  .assignees-list-wrapper {
    display: none;
    align-items: center;
    flex-direction: column;
    background-color: white;
    position: absolute;
    right: -200px;
    width: 200px;
    height: fit-content;
    max-height: 250px;
    overflow-y: auto;
    z-index: 4;
    border-radius: 8px;
    box-shadow: 0px 0px 10px -4.5px rgba(33, 27, 27, 0.866);

    .assignee {
      display: inline;
      padding: 1em 0.5em;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
}

@media screen and (min-width: 2600px) {
  .project-card-container {
    min-width: 100%;
    justify-content: space-between;
  }
}
