@import "../../../styles/vars.scss";

.schedule-wrapper {
  display: flex;
  width: 100%;
  height: 75vh;
  position: relative;
  overflow: auto;
  @include hiddenScrollbar;
  @include scrollbarHorizontal;
  border-radius: 3px;
}

.schedule-weekly-monthly-main {
  display: flex;
  flex-direction: column;
  width: 8.25em;
  height: 100%;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: $white;

  .arrows-wrapper {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 0.75em 1em;
    min-height: 43px;
    width: 131px;
    background-color: $main-background-color;
    z-index: 3;
    border-radius: 0 0 0 8px;

    .arrows {
      display: flex;
      position: fixed;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      width: 130px;
      min-height: 43px;
      @include bottomShadow;
      border-radius: 0 0 0 8px;
      background-color: $main-background-color;
      
    img {
      padding: 0 0.6em;
      cursor: pointer;
      // position: fixed;
    }
  }
  }

  .schedule-weeks {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-left: 0;
    margin: 0 auto;
    list-style-type: none;
    border-right: 1px solid $input-border;
    width: 8.25em;
    padding-top: 1em;
    background-color: $white;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0.5em 0;
      padding-bottom: 1em;
      border-bottom: 1px solid $input-border;
      width: 8.25em;
      height: 9.25em;
      width: 100%;

      h2 {
        margin: 0;
        font-size: 1.25rem;
      }

      h3 {
        margin: 0 auto;
        font-size: 1rem;
      }

      .weekday {
        font-weight: 400;
      }
    }

    .selected-day {
      h2,h3 {
        color: $dark-blue;
      }
    }
  }

  img {
    // padding-left: 1em;
    cursor: pointer;
  }
}

.weekly-monthly-orders-wrapper {
  // width: 96%;
  // min-height: 100%;
  // @include flexCenter(row);
  // align-items: flex-start;
  // position: relative;

  display: flex;
  width: 96%;
  height: 100%;
  align-items: flex-start;
  position: relative;
  padding-top: 0;

  &.last {
    border-right: none;
  }

  &:last-child {
    border-right: none;
  }

  .line-background-wrapper {
    min-height: 100%;
    width: 100%;
    top: 3.2em;
    list-style-type: none;
    z-index: 0;
    position: absolute;
    @include flexStart(column);

    li {
      margin-top: 1em;
      height: 9.25em;
      width: 100%;
      border-bottom: 1px solid $light-border-calendar;
    }
  }

  .order-main-title {
    margin-top: 0;
    position: sticky;
    top: 0;
    background-color: $main-background-color;
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.65em;
    text-align: center;
    @include bottomShadow;
    z-index: 2;
    height: 43px;
    width: 29.5vw;
  }

  .weekly-monthly-order-wrapper {
    position: relative;
    display: flex;
    flex: 1;
    @include flexCenter(column);
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    clear:both; 
    z-index: 1;

    &.machine {
      width: 570px;
    }

    // &.month {
    //   min-height: 4700px;
    // }

    .order-main-title {
      margin-top: 0;
      position: sticky;
      top: 0;
      background-color: $main-background-color;
      width: 100%;
      font-size: 1rem;
      font-weight: 500;
      padding: 0.65em;
      text-align: center;
      @include bottomShadow;
      z-index: 2;
      height: 43px;
      width: 29.5vw;

      &.machine {
        width: 570px;
      }
    }

    .render-container {
        position: absolute;
        // top: 4.1em;
        padding-top: 3.8em;
        padding-left: 0.5em;
        min-height: 100%;
        width: 100%;
        border-right: 1px solid $input-border;

        div {
        padding-bottom: 1em;
        margin-bottom: 1em;
        height: 148px;
        //   height: 9.22em;
        }

        &.last {
          border-right: none;
        }

        .weekday-requests-container {
            height: 148px;
            margin-bottom: 1em;
            padding-bottom: 0.4em;
            overflow-y: auto;
            @include hiddenScrollbar;

            .single-order-wrapper {
                @include taskContainer;
                padding: 0.85em 1em;
                min-width: 97%;
                position: relative;
                // height: 0.1em;
                height: fit-content;
                // max-height: 5em;
                margin: 0.5em 0 0 0.2em;
                font-size: 0.8rem;
                cursor: pointer;
                overflow: hidden;
                border-left: 10px solid $task-green;

                .product-name, .product-activity {
                  @include smallerHeading;
                  font-size: 14px;
                  font-family: $fonts-regular;
                }

                .product-activity {
                  @include tableHeaderElement;
                  font-size: 16px;
                  // text-overflow: ellipsis;
                  // overflow: hidden;
                  // display: inline;
                }

                .icons-wrapper {
                  position: absolute;
                  top: 0;
                  right: 0;
                  // width: 100%;
                  display: flex;
                  align-items: flex-start;
                  // justify-content: flex-end;
                  // margin-top: 15px;
                  margin-right: 5px;
      
                  p {
                    margin: 0;
                    font-size: 16px;
                    font-family: $fonts-bold;
                    color: #4D5D6E;

                    &.white {
                      color: $white;
                    }
                  }
      
                  .icon {
                    margin: 0 5px;
                  }
                }

                &.finished {

                  h3 {
                    color: $white;
                    background-color: $task-green;
                  }
                  background-color: $task-green;
                }
              }
        }
    }
  }
}

.weekend-label {
  position: absolute;
  // position: sticky;
  // left: 0;
  display: flex;
  width: 100%;
  align-self: flex-end;
  z-index: 3;
  bottom: -13.9em;
  padding: 0.5em 0;
  @include bottomShadow;
  border-radius: 5px;
  margin-bottom: 3em;
  background-color: $light-blue;

  &.fixed {
    bottom: -40px;
    width: 96vw;
    position: fixed;
  }

  h4 {
    @include smallestHeading;
    font-family: $fonts-bold;
    padding-left: 0.7em;
  }

  img {
    padding-left: 1.4em;
    cursor: pointer;

    &.arrow-up {
      padding-left: 1.4em;
      transform: rotate(180deg) translateX(-50%);
      transform-origin: center;
      transition: 0.3s;
    }

    &.arrow-down {
      transition: 0.3s;
    }
  }
}
