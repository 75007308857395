@import "vars";
@import "./base.scss";
// @import "./styles/vars.scss";
#root {
  display: flex;
}
body {
  background-color: #f2f5f7;
  box-sizing: border-box;
}
.dashboard-wrap {
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
}

.main-container {
  padding: 1vw;
  width: 100%;
  height: 95%;
  .inner-header-container {
    width: 100%;
    height: 6%;
    border-radius: 7px;
    padding-left: 1%;
    display: flex;
    align-items: center;
    @include shadow-large;
    background-color: white;
    .left-part {
      display: flex;
      height: 100%;
      align-items: center;
      width: 97%;
    }
    .inner-title {
      font-size: 1.2rem;
      margin-left: 1%;
      text-transform: uppercase;
      font-weight: bolder;
      white-space: nowrap;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: black;
    margin: 5px 0px;
    border-radius: 7px;
    &.vertical {
      height: 100%;
      width: 1px;
      margin: 0px 10px;
    }
  }
  .body-container {
    height: 91%;
    margin-top: 0.5%;
    border-radius: 7px;
    padding: 1vw;
    @include shadow-large;
    background-color: white;
    &.steps {
      width: 20%;
      box-shadow: none;
      padding: 0px;
      margin-right: 1%;
    }
    &.body-map {
      padding: 0px;
      box-shadow: none;
      margin-top: 0%;
    }
    &.row {
      display: flex;
    }
    &.full-height {
      height: 100%;
    }
    &.full-width {
      width: 100%;
    }
    &.no-margin {
      padding: 0;
      margin-top: 0;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 5px 0px;
  border-radius: 7px;
  &.vertical {
    height: 100%;
    width: 1px;
    margin: 0px 10px;
  }
}
.flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
  &.w-full {
    width: 100%;
  }
  &.items-start {
    align-items: flex-start !important;
  }
  &.items-center {
    align-items: center;
  }
  &.items-end {
    align-items: flex-end;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-around {
    justify-content: space-around;
  }
  &.justify-evenly {
    justify-content: space-evenly;
  }
  &.wrap {
    flex-wrap: wrap;
  }
}
.pointer {
  cursor: pointer;
}
.full-height {
  height: 100% !important;
}
.full-width {
  width: 100%;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.row {
  display: flex;
  flex-direction: row;
  //width: 100%; скапва много от npm пакетите - Алекс
}

.column {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
