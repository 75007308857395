

.filters-container {
  width: fit-content;
  display: flex;
  align-items: center;
  button {
    // margin-right: 40px;
    width: 200px;
    font-size: 14px;
    text-transform: none;
    color: black;
    white-space: nowrap;
  }
  .button-small {
    margin-right: 0px;
  }
  button:nth-of-type(1),
  button:nth-of-type(2) {
    width: 250px;
  }

  .scroll-container {
    display: flex;
    flex-direction: column;
    height: 500px;
    width: 300px;
    overflow-y: auto;
    margin-top: 10px;
    // @include scrollbar;
  }
}
.filters-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
svg {
  cursor: pointer;
}
.form-control {
  height: 220px;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.form-control::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}


