.edit-request-container {
  // height: 40vh;
  &.edit {
    margin-right: 40px;
    width: 70%;
  }
  button {
    text-transform: none;
    margin-right: 10px;
    padding: 5 25px;
  }
  .edit-request-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .form-info {
      display: flex;
      align-items: center;
      h3 {
        padding-right: 30px;
      }
    }
  }
  .arrow-right-coloured {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    background-image: url("../../assets/icons/arrow-right-filled.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 68%;
    padding: 20px;
    .form-upperline {
      display: flex;
      flex-direction: column;
      // align-items: center;
      // width: 68%;
      flex-wrap: wrap;
      &.edit {
        flex-direction: row;
        align-items: center;
        // justify-content: space-between;
        // width: 80%;
      }
      .form-control {
        margin-left: 15px;
      }
    }
  }
}
