@import "../../styles/vars.scss";

.flex-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;

  label {
    font-size: 12px;
    font-family: $fonts-regular;
    text-align: start;
    width: 100%;
  }

  .input-form-container {
    display: flex;
    justify-content: flex-start;
    flex-basis: 65%;

    .search-value-img-wrapper {
      display: flex;
      cursor: pointer;
      padding-right: 2em;
      transition: 0.5s;

      &.production {
        .text-input-container .text-input {
          cursor: default;

          &:hover {
            transition: none;
            background-color: $white;
          }
        }
      }

      &.expense {
        display: flex;
        flex-direction: column;
        padding-right: 0.6em;

        .flex-container {
          position: relative;
          margin-bottom: 0;
          width: fit-content;

          .text-input-container {
            // width: fit-content;
            // width: 80%;
            width: 250px;
            position: relative;
  
            .text-input {
              border-radius: 5px;
              // width: fit-content;
              caret-color: transparent;
            }
          }

          img {
            position: absolute;
            right: 5%;
            top: 45%;
          }
        }
      }

      &:hover {
        .text-input {
          transition: 0.5s;
          background-color: #f0f7fb;
        }
      }

      .text-input-container {
        white-space: nowrap;
        flex-wrap: nowrap;
        width: 9.375em;
        
        .text-input {
          height: 36px;
          cursor: pointer;
          transition: 0.5s;

          div {
            text-align: center;
            border-left: solid 1px rgba(128, 128, 128, 0.5);
            padding-left: 0.5em;
            padding-top: -2em;
          }
        }
      }
    }

    .search-col {
      display: flex;
      align-items: flex-end;
      position: relative;
      height: 100%;

      .search-value-img-wrapper {
        .text-input {
          height: 36px;
          border-radius: 0 7px 7px 0;
          cursor: pointer;
          transition: 0.5s;
        }
      }

      .search-input-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-input-container {
          width: 15.625em;
          // height: 2.1em;

          .text-input {
            border-right: none;
            height: 36px;
            border-radius: 7px 0 0 7px;
          }
        }
      }

      .btn-wrapper {
        margin-bottom: 0.65em;
        margin-left: 0.5em;
        margin-right: 4em;

        &.expense {
          margin-bottom: 0.4em;
          margin-left: 0.6em;
          cursor: pointer;
        }

        button {
          border: 0.03px solid #005fa36e;
          border-radius: 7px;
          box-shadow: none;
          font-size: 14px;
          font-family: $fonts-regular;
          width: 100px;
          height: 35px;
        }
      }
    }

    .filter-col {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      .filter-dates {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-right: 0.7em;

        .dates-wrapper {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          align-items: center;

          .dash {
            text-align: center;
            width: 100%;
            margin: 0.5em;
          }

          .react-datepicker-wrapper {
            width: 120px;
          }
        }
      }

      .search-dates {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .input-btn-wrapper {
          display: flex;

          .text-input-container {
            border-right: none;
          }

          .arrow-icon {
            margin-left: 0.5em;
          }
        }

        .btn-wrapper {
          margin-left: 0.6em;
          align-self: center;
          align-items: flex-end;
          display: flex;
          justify-content: space-between;
          flex-wrap: nowrap;
          margin-bottom: 0.6em;

          button {
            width: 100px;
            height: 35px;
            font-size: 14px;
            font-family: $fonts-regular;
            border: 1px solid #005fa36e;
            border-radius: 7px;
            box-shadow: none;
            margin-right: 1em;
            margin-top: 0.5em;
            background-color: $light-blue;
          }

          .remove-filter-btn {
            border: 1px solid #005fa36e;
            border-radius: 7px;
            box-shadow: none;
            margin-right: 1em;
            background-color: $light-blue;
            width: 35px;
            height: 35px;
            min-width: 35px;
            min-height: 35px;
            margin-left: 1.4em;
          }
        }
      }
    }
  }

  .btn-wrapper {

    .raised-button-container {
      max-width: 13.25em;
      height: 2.5em;
      font-size: 1rem;
      white-space: nowrap;
      // margin-right: 1.2em;
      background-color: $task-green;

      &.add-counterparty-btn {
        width: 15.5em;
        font-size: 1rem;
      }
    }
  }

  .scroll-container {
    overflow-y: scroll;
    padding: 0 5px;
    max-height: calc(100% - 100px);
    min-height: calc(100% - 100px);
  }
}

// .scroll-container {
//   overflow-y: scroll;
//   padding: 0 5px;
//   max-height: calc(100% - 100px);
//   min-height: calc(100% - 100px);
// }

.table-header-container {
  padding: 0 5px;
  flex-wrap: nowrap;
  white-space: nowrap;
}

@media screen and (max-height: 800px) and (max-width: 1600px) {
  .flex-container {
    .input-form-container {
      flex-wrap: wrap;

      .search-col {
        height: fit-content;
      }

      .filter-col {
        margin-top: 15px;
      }
    }
  }
}