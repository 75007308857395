.calendar-weekly-container {
  --cell-height: 90px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  .inner-container {
    display: flex;
    --days-to-show: 7;
    .calendar-navigation {
      border-right: 0.5px solid #a2bfd5;
      .day-arrows {
        display: flex;
        box-shadow: 0 4px 2px -2px grey;
        background-color: #f5f7fa;
        height: 54px;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
      }
      .cell-date {
        min-height: calc((100% - 80px) / var(--days-to-show));
        white-space: pre;
        border-bottom: 1px solid #a2bfd5;
        &.today p {
          color: #005ea3;
        }
        p {
          text-align: center;
          font-size: 1rem;
          line-height: 1.5rem;
          margin: 0;
          &.bold {
            font-weight: 700;
            &:first-line {
              font-size: 1.375rem;
            }
          }
        }
      }
    }
  }
  .scroll-container {
    display: flex;
    // justify-content: flex-;
    width: calc(100% - 100px);
    .col {
      min-height: 300px;
      height: 100%;
      flex: 1 1;
      min-width: 25%;
      border-right: 1px solid #a2bfd5;
      .cell-machine {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        box-shadow: 0 4px 2px -2px grey;
        background-color: #f5f7fa;
        align-items: center;
        margin-bottom: 18px;
        p {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 700;
        }
      }
      .cell-day {
        border-bottom: 1px solid #a2bfd5;
        min-height: calc((100% - 74px) / var(--days-to-show));
        display: flex;
        flex-direction: column;
        overflow: auto;
        .weekly-action {
          width: 100%;
          height: 35px;
          border-left: 5px solid #25e083;
        }
      }
    }
  }
  .row-weekend {
    position: sticky;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-color: #e6eef4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
