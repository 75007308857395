@import "../../styles/vars.scss";

.production-container {
  // max-height: 100vh;
  height: 96vh;
  width: 96vw;
  margin: 1em auto;
  // overflow: scroll;
  overflow: hidden;
  // overflow: auto;
  background-color: white;
  margin-top: 1.5em;

  .production-top-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: 100vw;

    .production-wrapper {
      // @include flexCenter(row);
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      padding: 0 1em;
      margin: 0;
      padding-bottom: 1em;
      background: $white;
      position: relative;
    }

    .current-date {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 0.3;
      white-space: nowrap;
      font-weight: 600;
      font-size: 1.1rem;
      width: 12em;
      margin-left: 0.6em;

      img {
        margin-right: 0.7em;
      }
    }

    .date-type-wrapper {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      flex: 2;
      // margin: 0 1.5em;
      // margin-left: 3em;
      // margin-right: 23.2em;
      padding: 0;

      .date-type-wrapper-li {
        border-bottom: 2px solid $main-background-color;
        display: flex;
        align-items: center;
        list-style-type: none;
        width: 24em;

        align-self: center;
        justify-content: center;
        padding: 0;
      }

      li {
        margin: 0 1.8em;
        padding-bottom: 0.5em;
        cursor: pointer;
        font-size: 1.125rem;
      }

      li:last-child {
        padding-right: 0;
      }

      .selected-tab {
        border-bottom: 5px solid $hydro;
        font-weight: bold;
      }
    }

    .file-buttons-wrapper {
      @include flexCenter(row);

      .popup {
        
        &.popup-animation-enter-done {
          width: 300px;
          height: 386px;
          top: 0;
          left: 2em;
          z-index: 4;
        }
      }

      img {
        max-width: 1.4em;
        cursor: pointer;
      }

      .request-primary-btn {
        @include primaryBtnSmall;
        white-space: nowrap;
      }

      .request-secondary-btn {
        @include secondaryBtnSmall;
        white-space: nowrap;
      }
    }
  }

  // .daily-weekly-monthly-schedule-wrapper {
  //     @include basicShadow;
  //     // height: 70vh;
  //     // overflow: scroll;
  //     // @include hiddenScrollbar;
  // }
}

// .names-titles {
//     display: flex;
//     flex-direction: row;
//     width: 85%;
//     align-items: center;
//     padding: 0.2em 0;
//     background-color: $main-background-color;
//     max-height: 100%;
// }

@media screen and (max-width: 1450px) {
  .date-type-wrapper {
    padding-right: 2em;
    justify-content: center;
  }
}

// @media screen and (max-width: 900px) {
//   .production-container {
//     .production-top-container {
//       .production-wrapper {
//         flex-direction: column;
//       }

//       .current-date {
//         padding: 10px 0;
//       }
//     }
//   }
// }

@media screen and (max-height: 800px) and (max-width: 1600px) {
  .production-container {
    .production-top-container {
      .production-wrapper {
        flex-direction: column;
        align-items: center;
      }

      .current-date {
        // font-size: 14px;
        width: fit-content;
        padding: 10px 0;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .production-container {
    .production-top-container {
      .current-date {
        text-wrap: wrap;
        font-size: 16px;
      }
      .date-type-wrapper {
        li {
          margin: 0 15px;
        }
      }
      .file-buttons-wrapper {
        flex-wrap: wrap;
      }
    }
  }
}
