@import "../../../styles/vars.scss";

.schedule-wrapper-daily {
  display: flex;
  width: 100%;
  max-height: 73vh;
  // max-height: 100%;
  position: relative;
  overflow: scroll;
  position: relative;
  @include hiddenScrollbar;
  @include scrollbarHorizontal;

  .schedule-daily-main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 4em;
    position: sticky;
    left: 0;
    z-index: 10;
    background-color: $white;
    // width: 5%;
    // height: 70vh;
    // height: 100vh;
    // position: relative;

    .arrows-wrapper {
      // position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      padding: 0.75em 1.5em;
      min-height: 43px;
      width: 60px;
      background-color: $main-background-color;
      // @include bottomShadow;
      border-radius: 0 0 0 8px;

      .arrows {
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        width: 60px;
        min-height: 43px;
        @include bottomShadow;
        border-radius: 0 0 0 8px;
        background-color: $main-background-color;
        
      img {
        padding: 0 0.45em;
        cursor: pointer;
        // position: fixed;
      }
    }
    }

    .schedule-hours {
      display: flex;
      flex-direction: column;
      // justify-content: flex-start;
      align-items: center;
      text-align: center;
      padding: 0;
      // z-index: 1;
      padding-right: 0.4em;
      margin-top: 5em;
      list-style-type: none;
      width: 61px;
      border-right: 1px solid $input-border;
      margin-top: 0;

      li {
        // margin: 0.46em 0.5em;
        margin: 0 0.5em;
        padding: 2em 0;
        width: 100%;
        font-size: 0.875rem;
        height: 23px;
        font-family: $fonts-regular;
      }

      .selected-hour {
        font-weight: 700;
      }

      :last-child {
        margin-bottom: 0;
        padding-bottom: 1em;
      }
    }

    .headers-wrapper {
      display: flex;
      flex-wrap: nowrap;

      .order-main-title {
        margin-top: 0;
        background-color: $main-background-color;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.65em;
        text-align: center;
        z-index: 11;
        height: 43px;
        width: 30.8vw;
        @include bottomShadow;

        // &.horizontal-scroll {
        //   flex: 1;
        //   width: 50%;
        //   width: 45%;
        // }

        &.machine {
          position: fixed;
          min-height: 43px;
        }
      }
    }
  }

  .daily-orders-wrapper {
    // @include flex(row);
    display: inline-flex;
    width: 100%;
    min-height: 100%;
    // min-height: 90vh;
    align-items: flex-start;
    position: relative;
    padding-top: 0;

    .line-background-wrapper {
        min-height: 100%;
        min-width: 100%;
        right: 0;
        top: 2.2em;
        list-style-type: none;
        z-index: 0;
        position: absolute;
        @include flexStart(column);
        justify-content: flex-start;

        li {
            height: 40px;
            min-width: 100%;
            margin: 0.5em 0em;
            padding-bottom: 0.5em;
            border-bottom: 1px solid $input-border;
          }
    }

     // order
    .daily-order-wrapper {
      display: flex;
      min-height: 800px;
      width: 100%;
      // z-index: 1;
      flex: 1;
      position: relative;
      margin-bottom: 0;

      .line-background-wrapper {
        min-height: 100%;
        min-width: 100%;
        right: 0;
        top: 2.2em;
        list-style-type: none;
        z-index: 0;
        position: absolute;
        @include flexStart(column);
        justify-content: flex-start;

        li {
            height: 40px;
            min-width: 100%;
            margin: 0.5em 0em;
            padding-bottom: 0.5em;
            border-bottom: 1px solid $input-border;
          }
      }

      .render-container {
        position: absolute;
        top: 4.45em;
        padding-top: 0.6em;
        min-height: 100%;
        width: 100%;
        overflow-y: hidden;
        border-right: 1px solid $input-border;

        div {
          padding-bottom: 1.75em;
          height: 27px;
          // border-bottom: 1px solid $input-border;
        }

        &.last {
          border-right: none;
        }
      }

      .order-main-title {
        position: sticky;
        top: 0;
        margin-top: 0;
        background-color: $main-background-color;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.65em;
        text-align: center;
        z-index: 7;
        height: 43px;
        width: 30.8vw;
        @include bottomShadow;

        &.horizontal-scroll {
          flex: 1;
          // width: 50%;
          // width: 45%;
        }

        // &.machine {
        //   min-height: 43px;
        // }
      }

      span {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .single-daily-order-wrapper {
        @include taskContainer;
        max-width: 100%;
        padding: 1.5em 1em;
        // padding: 2em 1em;
        justify-content: none;
        position: relative;
        cursor: pointer;
        overflow: hidden;
        // @include hiddenScrollbar;

        h3 {
            font-size: 0.9rem;
            font-family: $fonts-light;
            width: 100%;
            margin: 0.2em;
            // padding: 0.5em;
            max-height: 100vh;
            background-color: $main-background-color;
          }

          .icons-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 15px;
            margin-right: 5px;

            p {
              margin: 0;
              font-size: 16px;
              font-family: $fonts-bold;
              color: #4D5D6E;

              &.white {
                color: $white;
              }
            }

            .icon {
              margin: 0 5px;
            }
          }

          span {
            font-family: $fonts-bold;
          }

          &.finished {

            h3 {
              color: $white;
              background-color: $task-green;
            }
            background-color: $task-green;
          }
      }
    }
  }

  .schedule-current-time {
    // display: flex;
    right: 0;
    position: absolute;
    width: 96.3%;
    height: 0;
    border-bottom: 1px solid $hydro;
    z-index: 6;

    img {
      position: absolute;
      left: -1em;
      top: -0.9em;
      z-index: 1;
    }
  }

  .night-shift-label {
    position: absolute;
    // bottom: -6.3em;
    // bottom: -15%;
    bottom: calc(100% - 786px);
    right: 0;
    width: 95.8%;
    height: 0;
    border-bottom: 5px solid $dark-blue;
    z-index: 6; 
    @include bottomShadow;

    img {
      position: absolute;
      left: -1em;
      top: -0.4em;
      z-index: 1;
    }
  }
}

@media screen and (max-height: 800px) and (max-width: 1600px) {
  .schedule-wrapper-daily {
    .daily-orders-wrapper {
      .daily-order-wrapper {
        min-height: 1200px; 
      }
    }
  }
}
