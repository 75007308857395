@import "../../styles/vars.scss";

.home-page-wrapper {
  height: 93vh;
  width: 96vw;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  overflow: hidden;
  background-color: white;
  margin-top: 1.5em;

  .home-top-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // max-width: 100vw;
    width: 100%;

    .home-top-inner-wrapper {
      // @include flexCenter(row);
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 100%;
      padding: 0 1em;
      margin: 0;
      padding-bottom: 1em;
      background: $white;
      position: relative;
      width: 100%;

      &.tasks {
        .icon-button-wrapper {
          margin: 0;
          cursor: pointer;

          img {
            width: 34px;
            height: 34px;
          }
        }
        
      }

      .tab-selection-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 13%;

        h3 {
            font-size: 22px;
            font-family: $fonts-bold;
        }

        .flex {
            display: flex;
            justify-content: space-between;
        }

        .tab-icon {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            transition: 0.5s;
            height: fit-content;
    
            &.selected {
              background-color: $hydro;
              border-radius: 5px;
              transition: 0.5s;
    
              .img {
                fill: $white;
                filter: brightness(0) invert(1);
              }
            }
    
            .img {
              width: 24px;
              height: 24px;
            }
          }
      }
    }

    // .icon-button-wrapper {
    //   @include flexCenter(row);
    //   justify-content: space-between;

    //   img {
    //     max-width: 1.4em;
    //     cursor: pointer;
    //   }

    //   .primary-btn {
    //     @include primaryBtnSmall;
    //     white-space: nowrap;
    //   }

    //   .secondary-btn {
    //     @include secondaryBtnSmall;
    //     white-space: nowrap;
    //   }
    // }

    .current-date {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex: 0.3;
      white-space: nowrap;
      font-weight: 600;
      font-size: 1.1rem;
      width: 5%;
      margin-left: 0.6em;

      img {
        margin-right: 0.7em;
      }
    }

    .date-type-wrapper {
      display: flex;
      align-items: center;
      align-self: center;
      justify-content: center;
      flex: 2;
      // margin: 0 1.5em;
      // margin-left: 3em;
      // margin-right: 23.2em;
      padding: 0;

      .date-type-wrapper-li {
        border-bottom: 2px solid $main-background-color;
        display: flex;
        align-items: center;
        list-style-type: none;
        width: 24em;

        align-self: center;
        justify-content: center;
        padding: 0;
      }

      li {
        margin: 0 1.8em;
        padding-bottom: 0.5em;
        cursor: pointer;
        font-size: 1.125rem;
      }

      li:last-child {
        padding-right: 0;
      }

      .selected-tab {
        border-bottom: 5px solid $hydro;
        font-weight: bold;
      }
    }
  }

  .home-main-container {
    width: 96vw;;
    display: flex;
    gap: 15px;
    // align-items: flex-start;
    justify-content: flex-start;
    height: 92vh;
  }
}
