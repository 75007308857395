.mobile-file-container {
  background-color: #e6eef4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 5px;
  color: #26ace2;
  font-size: 14px;
  width: 95%;
  margin-right: 15px;
  height: 100%;
}
