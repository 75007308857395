@import "../../../styles/vars.scss";

.production-pipes-container {
    background-color: #F2F5F7;
    width: 100%;

    .production-tabs {
      display: flex;
      position: relative;
      max-width: 60%;
  
      .tab {
        @include flexCenter(row);
        cursor: pointer;
        // min-width: 3.5em;
        min-height: 2em;
        white-space: nowrap;
        padding: 0.5em 3.5em;
        margin: 0;
        background-color: white;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        box-shadow: 0px 0px 0px -10px rgba(0, 0, 0, 0.589);
        @include basicShadow;
        @include smallHeading;
        font-size: 1.25rem;
        width: 13.6em;
      }

      .selected {
        background-color: white;
        box-shadow: 5px -3px 10px -10px rgba(0, 0, 0, 0.589);
        // box-shadow: none;
        z-index: 1;   
        font-weight: 600;
      }
    }
    
  }

  // @media only screen and (max-width: 900px) {
  //   .production-pipes-container {
  //     .production-tabs {
  //       max-width: 100%;
  //       .tab {
  //         max-width: 210px;
  //         font-size: 0.9rem;
  //         flex: 1;
  //       }
  //     }
  //   }
  // }

@media screen and (max-height: 800px) and (max-width: 1600px) {
  .production-pipes-container {
    .production-tabs {
      max-width: 100%;
      .tab {
        max-width: 210px;
        font-size: 16px;
        flex: 1;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .production-pipes-container {
    .production-tabs {
      max-width: 100%;
      .tab {
        padding: 0 20px;
        font-size: 16px;
        min-width: 100px;
      }
    }
  }
}

