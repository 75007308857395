@import "../../vars.scss";
.production-container {
  height: 100vh;
  width: 90%;
  margin: 30px;
  overflow: auto;
  .production-tabs {
    display: flex;
    position: relative;
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      cursor: pointer;
      font-size: 20px;
      height: 40px;
      min-width: 150px;
      padding: 0 50px;
      background-color: rgba(233, 233, 233, 0.5);
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      box-shadow: 0px 0px 0px -10px rgba(0, 0, 0, 0.589);
      &.selected {
        background-color: white;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
      .production-pipes-container {
        .production-tabs {
          .tab,
          .selected {
            padding: 0 20px;
            font-size: 16px;
            min-width: 100px;
          }
        }
      }
  }