.pipes-machines-container {
  padding: 10px;
  width: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .dates-tabs {
    .MuiTabs-indicator {
      background-color: #f5f5f5 !important;
    }
  }
  .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    button {
      text-transform: none;
    }
    svg,
    img {
      cursor: pointer;
    }
    .time-container {
      display: flex;
      align-items: center;
      div {
        margin-right: 10px;
      }
    }

    .requests-container {
      display: flex;
      align-items: center;
      button {
        margin: 0 5px;
      }
    }
  }
}
.row-weekend {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 1vw;
  background: #e6eef4;
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 700;
  .rotate {
    transform: rotate(180deg);
  }
}
