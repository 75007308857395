@import "fonts";

$fonts-bold: "bold";
$fonts-medium: "medium";
$fonts-regular: "regular";
$fonts-light: "light";
$fonts-medium-italic: "medium-italic";
$fonts-thin: "thin";
$hydro: #26ace2;
$white: white;
$mainColor: #0b1a29;

$shadowColor: rgba(0, 0, 0, 0.589);
$shadowTabsColor: rgba(0, 52, 112, 0.15);
$textColor: #01102a;

$inputBorder: #c5c5c5;
$inputTextColor: rgba(0, 0, 0, 0.5);

@mixin basicShadow {
  box-shadow: 0px 0px 16px -10px $shadowColor;
}

@mixin sectionShadow {
  box-shadow: 0px 5px 15px 0px rgba(0, 52, 112, 0.15);
}

@mixin smallHeading {
  font-size: 20px;
  font-family: $fonts-medium;
  color: $mainColor;
}

@mixin smallerHeading {
  font-size: 16px;
  font-family: $fonts-regular;
  color: #152331;
  margin: 0px;
}

@mixin smallestHeading {
  font-size: 16px;
  font-family: $fonts-medium;
  color: #152331;
  margin: 0px;
}

.text-blue {
  color: #26ace2;
}

h3 {
  @include smallHeading;
}

h4 {
  @include smallerHeading;
}

h5 {
  @include smallestHeading;
}

@mixin emptyComponent {
  font-size: 18px;
  font-family: $fonts-light;
  color: $textColor;
  text-align: center;
}

@mixin scrollbarHorizontal() {
  scrollbar-color: #999;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 8px;
    width: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
