.daily-graph-container {
  --cell-height: 90px;
  display: flex;
  height: calc(100% - 60px);
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    height: 99%;
  }
  .current-time {
    position: absolute;
    height: 2px;
    width: calc(100% - 107px);
    right: 0;
    background: #0bd8de;
    top: 150px;
    &::before,
    &::after {
      box-shadow: 0px 0px 5px 0px #0bd8de;
      position: absolute;
      content: "";
      display: block;
      border-radius: 50%;
      left: 0;
    }
    &::before {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 1px solid #0bd8de;
      transform: translate(-15px, -7px);
    }
    &::after {
      width: 11px;
      height: 11px;
      background: #0bd8de;
      transform: translate(-13px, -5px);
    }
  }
  .daily-calendar {
    height: calc(24 * var(--cell-height) + 50px);
    width: 100px;
    > div {
      border-right: 1px solid rgba(194, 222, 239, 0.5);
    }
    .day-arrows {
      display: flex;
      box-shadow: 0 4px 2px -2px gray;
      background-color: #f5f7fa;
      height: 50px;
      align-items: center;
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .cell {
      width: 100%;
      justify-content: center;
    }
    .cell-date {
      height: var(--cell-height);
      text-align: center;
      justify-content: flex-start;
      padding: 0 20px;
      p {
        margin: 0;
        transform: translateY(-50%);
      }
    }
  }
  .machines-container {
    display: flex;
    width: calc(100% - 100px);
    min-height: calc(24 * var(--cell-height) + 50px);
    overflow-x: scroll;
    overflow-y: hidden;
    // justify-content: space-evenly;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    > div {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(194, 222, 239, 0.5);
      }
      > div {
        height: 50px;
      }
    }
    &:not(:last-child) {
      border-right: 1px solid rgba(194, 222, 239, 0.5);
    }
    .machine-container {
      min-height: 300px;
      height: 100%;
      flex: 1;
      min-width: 25%;
      border-right: 1px solid #a2bfd5;
      // &.contractor {
      //   min-width: 15%;
      // }
      .machine-name {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        box-shadow: 0 4px 2px -2px gray;
        background-color: #f5f7fa;
        align-items: center;

        p {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 700;
          margin: 0;
        }
      }
      .cell-day {
        height: var(--cell-height);
        padding: 0 4%;
        display: flex;
        position: relative;
        border-top: 1px solid #a2bfd5;
        // border-right: 1px solid #A2BFD5;
      }
    }
    p {
      margin: 0;
    }
  }
  .cell-session {
    background-color: rgba(245, 247, 250, 1);
    width: 100%;
    border-left: 10px solid #25e083;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
