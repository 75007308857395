$color-white: #ffffff;
$color-whiteish: #f8f8f8;
$color-gray: #7e7e7e;
$color-gray-ligth: #efefef;

$color-green: #0bd84f;
$color-pink: #ed1d5d;
$color-blue: #0bd8de;
$color-blue-dark: #1076b5;
$color-blue-dark2: #103cb5;
$color-magenta: #d814a9;
$color-orange: #ff5800;

$color-text: #0d242e;

$colors-shadow: #00347026;

@mixin shadow-small {
  box-shadow: 0px 1px 4px 0px $colors-shadow;
}
@mixin shadow-large {
  box-shadow: 0px 5px 15px 0px $colors-shadow;
}

@mixin remove-scrollbars {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

%flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
} //  @extend %flex;

// @font-face {
//   font-family: 'Montserrat';
//   src: url('assets/fonts/Montserrat-Thin.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 100;
// }
// @font-face {
//   font-family: 'Montserrat';
//   src: url('assets/fonts/Montserrat-Light.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 300;
// }
// @font-face {
//   font-family: 'Montserrat';
//   src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 400;
// }
// @font-face {
//   font-family: 'Montserrat';
//   src: url('assets/fonts/Montserrat-Medium.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 500;
// }
// @font-face {
//   font-family: 'Montserrat';
//   src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
//   font-style: normal;
//   font-weight: 700;
// }
