@import '../../styles/vars.scss';

// .inquiries-offers-component {
//   background-color: white;
//   height: calc(100% - 50px);
//   padding: 20px;

//   .scroll-container {
//     overflow-y: scroll;
//     padding: 0 5px;
//     @include scrollbar;
//     // max-height: calc(100% - 100px);
//     // min-height: calc(100% - 100px);
//     max-height: 53.5vh;
//     min-height: 30vh;
//   }
//   .table-header-container {
//     padding: 0 5px;
//   }
// }

@media screen and (max-width: 1100px) {
    .inquiries-clients-component.offers {
      .flex-container {
        .input-form-container {
          flex-wrap: wrap;
          gap: 20px;
  
          .search-col {
            height: unset;
  
            .btn-wrapper {
              margin-right: 0;
            }
          }
        }
  
        .btn-wrapper {
          margin-top: auto;
          margin-right: 10px;
  
          .raised-button-container {
            padding: 0 20px;
          }
        }
      }
    }
  }
  
