.projects-container {
  button {
    text-transform: none;
    margin-left: 5px;
  }
  .projects {
    position: relative;
    height: 940px;
    margin: 20px;
    .projects-header {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search-container {
        display: flex;
        align-items: center;
        button {
          margin-left: 10px;
          text-transform: none;
        }
      }
      .search-radio-group {
        padding-left: 20px;
      }
      .datepicker-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .divider {
          margin: 0 5px;
        }
      }
    }
    .all-projects {
      padding: 50px 10px 10px 10px;
      height: 90%;
      width: 100%;
      overflow: auto;
    }
    .statistics-container {
      position: sticky;
      bottom: 0;
      width: 100%;
      height: 40px;
      background-color: #e6eef4;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .total-projects {
        border-right: 0.2px solid black;
      }
      p {
        padding: 5px;
      }
    }
  }
}
.datepicker {
  width: 150px;
}
.filters-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.top-projects-view-container {
  display: flex;
  flex-wrap: wrap;

  .search-wrapper,
  .query-search-wrapper, 
  .date-search-wrapper {
    display: flex;
    flex-wrap: wrap;
    // flex-wrap: nowrap;
  }

}


// @media screen and (max-height: 800px) and (max-width: 1600px) {
@media screen and (max-width: 1450px) {
  
    .search-wrapper {
      flex-wrap: wrap;
      width: 80%;
      // order: 2;
    }
    .query-search-wrapper,
    .date-search-wrapper {
      margin-left: 16px;
      margin-bottom: 20px;
    }
  
}