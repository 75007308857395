@import '../../../styles/vars.scss';

.comments-modal-container {
  background-color: #f9fafc;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-around;
  .comments-header-container {
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .comments-heading {
      display: flex;
      align-items: center;
    }
  }
  .comments-container {
    overflow: auto;
    @include scrollbarHorizontal;
    height: 600px;
    .comment {
      padding: 10px 30px;
      padding-top: 0;
      .comment-header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        &.me {
          justify-content: flex-end;
        }
        h5 {
          align-self: center;
          padding: 0 10px;
        }
      }
      &:last-child {
        padding-top: 12px;
      }
      &.me {
        text-align: right;
        .message-body {
          background-color: #e6eef4;
        }
      }
      .time {
        display: inline-block;
        font-size: 14px;
        padding: 10px;
      }
      .message-body {
        white-space: pre-line;
        background-color: #ffffff;
        font-weight: 400;
        display: inline-block;
        padding: 12px;
        border-radius: 10px;
        max-width: 750px;
        min-width: 150px;
        line-height: 20px;
        text-align: initial;
      }
    }
  }
  .form-container {
    display: flex;
    background-color: #effbfc;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
  }
}
