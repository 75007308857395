.activities-container {
  height: 85vh;
  width: 570px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .activities-list {
    display: flex;
    flex-direction: column;
    .activity {
      display: flex;
      flex-direction: column;
      border-bottom: 0.5px solid #005ea3;
      margin: 5px 20px;
      .activity-content {
        display: flex;
        align-items: center;
        p {
          margin-left: 10px;
        }
      }
    }
  }
}
