.request-form-container {
  height: 100%;
  width: 100%;
  button {
    text-transform: none;
  }
  .request-form {
    height: 40vh;
    margin: 30px;
    // overflow: auto;

    .form-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
    .form-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 70%;
      padding: 20px;
      .form-upperline {
        display: flex;
        align-items: center;
        width: 70%;
        justify-content: space-between;
      }
    }
  }
}
