.modal-container {
  width: 576px;
  height: 85vh;
  .modal-header {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e6eef4;
    box-shadow: 0 4px 2px -2px gray;
    svg {
      cursor: pointer;
    }
  }
  .modal-content {
    padding: 10px 20px;
    height: 95%;
    .products-filters {
      padding-top: 10px;
      display: flex;
      padding-right: 18px;
      button {
        color: black;
        text-transform: none;
        font-weight: bold;
      }
    }
    .products-container {
      height: 80%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      overflow: auto;
      .product {
        margin: 5px;
        height: 61px;
        display: flex;
        justify-content: space-evenly;
        cursor: pointer;
        &.progress {
          border-left: solid 7px #25e083;
        }
        &.done {
          border-left: solid 7px #f7e05d;
        }
        p {
          white-space: pre-line;
          // text-align: center;
          border-right: 1px solid gray;
          padding-right: 12px;
          &:last-of-type {
            border: none;
          }
        }
      }
    }
  }
}
